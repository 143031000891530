import React from 'react'

import { Link } from "gatsby"
import { normalize } from "../../hooks/normalize"

import Layout from "../share/layout";
import Footer from "../share/footer"
import { BlogContainer, NavContainer, MainContainer } from "./styles";
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, TumblrShareButton, PinterestShareButton } from "react-share";
import { useLocation } from "@reach/router";
import CategoriesResponsive from "../arteycultura/CategoriesResponsive";

const Blog = ({ pageContext: { dataSingle, type } }) => {
  const {
    name,
    description,
    credits,
    image,
    temps
  } = dataSingle;
  // console.log({ dataSingle })

  console.log({ name, description, image, temps, type })

  const { pathname } = useLocation()
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
      },
      [INLINES.HYPERLINK]: (node) => {
        if ((node.data.uri).includes("<iframe")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div className="rich-content-iframe" dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    },
    renderMark: {},
  }

  return (
    <Layout>
      <div class="upcc-container upcc-program">

        <section class="upcc-menu">
          <nav>
            <Link to={`/`}>Inicio</Link>&nbsp;/&nbsp;

            {type == 'program'
              ? <span>
                  <Link to={`/programas`}>Programas</Link>&nbsp;/&nbsp;
                  <Link to={`/programas/${normalize(name)}`}>{ name }</Link>
                </span>
              : <span>
                  <Link to={`/podcasts`}>Podcasts</Link>&nbsp;/&nbsp;
                  <Link to={`/podcasts/${normalize(name)}`}>{ name }</Link>
                </span>
            }

          </nav>
        </section>

        <section class="wall dark">
          <div class="inner">

            <div class="wall-col">

              <figure class="left small">
                <img class="upcc-img" src={ image.file.url }/>
              </figure>

              <div class="right">
                <h1 class="upcc-h1 white">{ name }</h1>

                <p>
                  Sobre el programa: <br/>
                  { description }
                </p> <br/>

                <p>
                  { credits.map((text, i) => <span>{ text } <br/></span>) }
                </p> <br/><br/>

                {type == 'program'
                  ? <Link to={`/programas/${normalize(name)}/${normalize(temps[0].eps[0].name)}`} class="upcc-button">Ver Ahora</Link>
                  : <Link to={`/podcasts/${ normalize(name) }/${ normalize(temps[0].eps[0].name) }`} class="upcc-button">Ver Ahora</Link>
                }
              </div>

            </div>

            <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
          </div>
        </section>

        <main>
          {
            temps.map(( temp, i ) => {
              return (
              <div>

                <section class="upcc-controls">
                  <div class="left">
                    <span class="bar"></span>
                    <h5 class="upcc-h5 white">Temporada { i + 1 } { temp.name }</h5>
                  </div>
                </section> <br/>

                <div class="upcc-list">
                  {
                    temp.eps.map(( ep, j ) => {

                      if(type == 'program') {
                        return (
                          <Link to={`/programas/${normalize(name)}/${normalize(ep.name)}`} class="upcc-element">
                            T{ i + 1}E{ j + 1 } - { ep.name } <i class="fa fa-play-circle"></i>
                          </Link>
                        )
                      } else {
                        return (
                          <Link to={`/podcasts/${ normalize(name) }/${ normalize(ep.name) }`} class="upcc-element">
                            E{ j + 1 } - { ep.name } <i class="fa fa-play-circle"></i>
                          </Link>
                        )
                      }
                    })
                  }
                </div> <br/><br/>
              </div>
              )
            })
          } <br/><br/><br/><br/><br/><br/>
        </main>

      </div>

      <Footer />
    </Layout>
  )
}

export default Blog
